import React, { useState } from "react";
import { Link, navigate } from "gatsby";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";

import ContentContainer from "./shared/ContentContainer";
import { PageContentLoadingIndicator } from "./shared/ContentLoadingIndicator";
import ContentParagraph, { PlanActivationContentParagraph } from "./shared/ContentParagraph";
import { MANAGE_SUBSCRIPTION_PATH, TENANT_PATH, PURCHASE_SUCCESSFUL_PATH } from "./shared/AuthClaimCheckHook";
import ContentTitle from "./shared/ContentTitle";
import { getPurchaseablePlan, ManageSubscriptionPlansList } from "./shared/PlanCard";
import PurchaseInformationForm from "./shared/PurchaseInformationForm";
import { SuccessContentEContract } from "./shared/PurchaseSuccessContent";
// import ContentButton from "./shared/ContentButton";
// import TextInput from "./shared/TextInput";
// import { SubmitButton } from "./shared/ContentButton";

import { useProductApi, useStripeApi } from "./trial/PurchaseContent";
import { useTenantDataApi, TENANT_API_BASE_URL } from "./TenantContent";

const useContractDataApi = () => {
    const { getAccessTokenSilently } = useAuth0();
    const [loading, setLoading] = useState(false);

    let contractController;
    const handleContractPDFDownload = async () => {
        try {
            contractController = new AbortController();
            const token = await getAccessTokenSilently();
            const { data: pdfData } = await axios.get(
                `${TENANT_API_BASE_URL}/contract`,
                {
                    signal: contractController.signal,
                    headers: { Authorization: `Bearer ${token}` },
                    responseType: 'blob',
                },
            );

            if (window) {
                const pdfBlob = new Blob([pdfData], { type: 'application/pdf' });
                const pdfURL = URL.createObjectURL(pdfBlob);
                window.open(pdfURL, '_blank');
            }
        } catch (e) {
        } finally {
            setLoading(false);
        }
    };

    React.useEffect(() => {
        return () => {
            if (contractController) contractController.abort();
        }
    }, []);

    return { handleContractPDFDownload, contractApiLoading: loading }
}

const ManageSubscriptionContent = () => {
    const { getProducts, products } = useProductApi();
    const { getTenantData, tenantData } = useTenantDataApi();
    const [mode, setMode] = React.useState('view'); // view, change, success
    const [currentPlan, setCurrentPlan] = React.useState();
    const [changeToPlanId, setChangeToPlanId] = React.useState();
    const { setStripeApiOptions, stripeApiLoading } = useStripeApi();
    const { handleContractPDFDownload } = useContractDataApi();

    React.useEffect(() => {
        getProducts();
        getTenantData();
    }, []);

    React.useEffect(() => {
        if (products && tenantData) {
            setCurrentPlan(getPurchaseablePlan(products, tenantData.SelectedFrendsPackage));
        }
    }, [products, tenantData]);

    const isStripeSubscription = () => {
        return tenantData.CustomerSubscriptionType === 2;
    };

    const getChangePlanButtonClickHandler = () => {
        return isStripeSubscription() ? undefined : (planId) => setChangeToPlanId(planId);
    };

    React.useEffect(() => {
        if (changeToPlanId) {
            setMode('change');
        }
    }, [changeToPlanId]);

    const handleChangePlanFormSendSuccess = () => {
        setMode('success');
    };

    const getDownloadContractHandler = () => {
        return isStripeSubscription() ? undefined : () => handleContractPDFDownload();
    }

    const getChangePlanWithStripeButtonClickHandler = () => {
        return isStripeSubscription() ? (planId) => {
            setStripeApiOptions({
                method: 'post',
                url: `${TENANT_API_BASE_URL}/purchase/stripe/generatepaymentlink`,
                data: { SelectedFrendsPackage: planId },
            });
        } : undefined;
    };

    const getViewSubscriptionInStripeHandler = () => {
        return isStripeSubscription() ? () => {
            setStripeApiOptions({
                method: 'get',
                url: `${TENANT_API_BASE_URL}/purchase/stripe/manage`,
            });
        } : undefined;
    };

    const getCancelSubsriptionInStripeHandler = () => {
        return isStripeSubscription() ? () => {
            setStripeApiOptions({
                method: 'delete',
                url: `${TENANT_API_BASE_URL}/purchase/stripe/cancel`,
            });
        } : undefined;
    };

    const handleBackLinkClick = () => {
        setMode('view');
    };

    React.useEffect(() => {
        if (mode === 'view') {
            setChangeToPlanId(undefined);
        }

        if (window) {
            window.scrollTo(0, 0);
        }
    }, [mode]);

    // const handleCancelPlanButtonClick = () => {
    //     setMode('cancel');
    // };

    return (
        <>
            {(!products || !currentPlan || stripeApiLoading) ? (
                <PageContentLoadingIndicator />
            ) : (
                <>
                    {mode === 'view' && (
                        <ViewContent
                            changeOnGoing={tenantData.CustomerOnGoingPurchase}
                            plans={products}
                            currentPlanId={currentPlan?.ProductName}
                            onChangePlanButtonClick={getChangePlanButtonClickHandler()}
                            onDownloadContract={getDownloadContractHandler()}
                            onChangePlanWithStripeClick={getChangePlanWithStripeButtonClickHandler()}
                            onViewSubscriptionInStripe={getViewSubscriptionInStripeHandler()}
                            onCancelSubsriptionInStripe={getCancelSubsriptionInStripeHandler()}
                        />
                    )}

                    {mode === 'change' && changeToPlanId && (
                        <ChangeContent
                            plans={products}
                            changeToPlanId={changeToPlanId}
                            onBackLinkClick={handleBackLinkClick}
                            onFormSendSuccess={handleChangePlanFormSendSuccess}
                        />
                    )}

                    {mode === 'success' && (
                        <SuccessContentEContract tenantLinkPath={TENANT_PATH} />
                    )}

                    {/* {mode === 'cancel' && tenantData && (
                        <CancelContent
                            tenantData={tenantData}
                            onBackLinkClick={handleBackLinkClick}
                        />
                    )} */}
                </>
            )}</>
    );
};

const ViewContent = ({
    changeOnGoing,
    plans,
    currentPlanId,
    onChangePlanButtonClick,
    onDownloadContract,
    onChangePlanWithStripeClick,
    onViewSubscriptionInStripe,
    onCancelSubsriptionInStripe,
}) => {
    return (
        <ContentContainer loading={!plans || !currentPlanId}>
            <ContentParagraph>
                <Link
                    to={TENANT_PATH}
                    className="is-purple is-underlined"
                >
                    Back to tenant view
                </Link>
            </ContentParagraph>
            <ContentTitle>
                Manage subscription
            </ContentTitle>
            <ContentParagraph style={{ maxWidth: '1262px' }}>
                Below you can see your active subscription tier in green. You can also upgrade your subscription to a greater tier. To downgrade your subscription, please contact us at <a href="mailto:support@frends.com">support@frends.com</a>.
            </ContentParagraph>

            {changeOnGoing && (
                <PlanActivationContentParagraph>
                    The new subscription is not yet active. The contract sent to your email has not been signed yet. Please sign it to get the subscription activated.
                </PlanActivationContentParagraph>
            )}

            <ManageSubscriptionPlansList
                plans={plans}
                currentPlanId={currentPlanId}
                onUpgradeContract={onChangePlanButtonClick}
                onDownloadContract={onDownloadContract}
                onUpgradeWithStripe={onChangePlanWithStripeClick}
                onViewSubscriptionInStripe={onViewSubscriptionInStripe}
                onCancelSubscriptionInStripe={onCancelSubsriptionInStripe}
            />

            {/* <ContentButton
                margin="50px 0 0 0"
                onClick={onCancelPlanButtonClick}
            >
                Cancel subscription
            </ContentButton> */}
        </ContentContainer>
    );
};

const ChangeContent = ({
    plans,
    changeToPlanId,
    onBackLinkClick,
    onFormSendSuccess,
}) => {
    return (
        <ContentContainer>
            <ContentParagraph>
                <BackToSubscriptionLink onBackLinkClick={onBackLinkClick} />
            </ContentParagraph>

            <PurchaseInformationForm
                plans={plans}
                selectedPlanId={changeToPlanId}
                onBackButtonClick={onBackLinkClick}
                onFormSendSuccess={onFormSendSuccess}
            />
        </ContentContainer>
    );
};

// const CancelContent = ({
//     tenantData,
//     onBackLinkClick,
// }) => {
//     const { getAccessTokenSilently } = useAuth0();
//     const reasonInputRef = React.useRef();
//     const tenantNameInputRef = React.useRef();

//     const [valid, setValid] = React.useState({
//         reason: true,
//         tenantName: true,
//     });

//     const [loading, setLoading] = React.useState();

//     const setInputInValidity = (inputName) => {
//         setValid({
//             reason: (inputName === 'reason' ? false : true),
//             tenantName: (inputName === 'tenantName' ? false : true),
//         });
//     };

//     const validateForm = () => {
//         if (!reasonInputRef.current.checkValidity()) {
//             setInputInValidity('reason');
//             reasonInputRef.current.focus();
//             return false;
//         }

//         if (!tenantNameInputRef.current.checkValidity()) {
//             setInputInValidity('tenantName');
//             tenantNameInputRef.current.focus();
//             return false;
//         }

//         setInputInValidity('none');
//         return true;
//     };

//     const onInputChange = () => {
//         setInputInValidity('none');
//     };

//     let controller;
//     const handleSubmitFormButtonClick = async (evt) => {
//         evt.preventDefault();

//         if (!validateForm()) {
//             return;
//         }

//         setLoading(true);

//         try {
//             controller = new AbortController();
//             const formData = new FormData(document.querySelector('.trial-form'));
//             const reasonValue = formData.get('reason');
//             const tenantNameValue = formData.get('tenantName');
//             const token = await getAccessTokenSilently();

//             const { data } = await axios.post(
//                 `${TENANT_API_BASE_URL}/TODO`,
//                 {
//                     TODO: reasonValue,
//                     TODO: tenantNameValue,
//                 },
//                 {
//                     signal: controller.signal,
//                     headers: { Authorization: `Bearer ${token}` }
//                 },
//             );

//             if (data.Status !== 'OK') {
//                 //TODO
//                 return;
//             }

//             //TODO
//         } catch (e) { }

//         onBackLinkClick()
//     };

//     React.useEffect(() => {
//         return () => {
//             if (controller) controller.abort();
//         }
//     }, []);

//     const getTenantName = () => {
//         return String(tenantData?.TenantURL).slice(8).split('.')[0];
//     };

//     return (
//         <ContentContainer>
//             <ContentParagraph>
//                 <BackToSubscriptionLink onBackLinkClick={onBackLinkClick} />
//             </ContentParagraph>
//             <ContentTitle>
//                 Cancel subscription
//             </ContentTitle>
//             <ContentParagraph>
//                 We are sorry to see you go. Please tell us why you want to cancel your subscription and input the tenant name.
//             </ContentParagraph>

//             <form className="trial-form">
//                 <TextInput
//                     ref={reasonInputRef}
//                     valid={valid.reason}
//                     disabled={loading}
//                     onChange={onInputChange}
//                     maxLength={512}
//                     required
//                     type="textarea"
//                     label="Why?"
//                     name="reason"
//                     errorText="A reason is required"
//                 />

//                 <TextInput
//                     ref={tenantNameInputRef}
//                     valid={valid.tenantName}
//                     disabled={loading}
//                     onChange={onInputChange}
//                     maxLength={13}
//                     required
//                     type="text"
//                     pattern={getTenantName()}
//                     label="Tenant name"
//                     name="tenantName"
//                     placeholder={getTenantName()}
//                     errorText="Check the tenant name"
//                 />

//                 <SubmitButton
//                     loading={loading}
//                     onClick={handleSubmitFormButtonClick}
//                 >
//                     Confirm the cancellation
//                 </SubmitButton>
//             </form>
//         </ContentContainer>
//     );
// };

const BackToSubscriptionLink = ({ onBackLinkClick }) => {
    const handleLinkClick = (evt) => {
        evt.preventDefault();
        onBackLinkClick();
    };

    return (
        <a
            className="is-purple is-underlined"
            href={MANAGE_SUBSCRIPTION_PATH}
            onClick={handleLinkClick}
        >
            Back to subscription
        </a>
    );
};


export default ManageSubscriptionContent;