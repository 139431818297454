import React from "react";

import { useAuthClaimCheck, TENANT_PATH, CONTRACT_AND_INVOICES_PATH, MANAGE_SUBSCRIPTION_PATH } from "../../components/profile/shared/AuthClaimCheckHook";
import PageContentContainer from "../../components/profile/shared/PageContentContainer";
import ManageSubscriptionContent from "../../components/profile/ManageSubscriptionContent";

import { pushSAEvent } from "../../helpers";

export default ({ location }) => {
    const loading = useAuthClaimCheck();

    const menuItems = [
        {
            path: TENANT_PATH,
            text: 'My Frends',
        },
        // {
        //     path: CONTRACT_AND_INVOICES_PATH,
        //     text: 'Contracts and Invoices',
        // },
        {
            path: MANAGE_SUBSCRIPTION_PATH,
            text: 'Manage Subscription',
            open: true,
        },
    ];

    pushSAEvent('visit_profile_manage_subscription');

    return (
        <PageContentContainer
            title="Your profile | Manage Subscription"
            location={location}
            loading={loading}
            menuItems={menuItems}
            content={<ManageSubscriptionContent />}
        />
    );
};